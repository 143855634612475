import React from "react"

class SpinnerComponent extends React.Component {
    render() {
        return (
            <div className="fallback-spinner d-flex  align-items-center justify-content-center m-auto vh-100 rtl">
                {/* <div className="d-flex">
                    <img src={logo} className="mr-auto ml-auto mt-2" style={{maxWidth: "200px"}} alt="شرکت توسعه نرم افزار باران"/>
                </div>*/}

                <div className="gearbox">
                    <div className="overlay"></div>
                    <div className="gear one">
                        <div className="gear-inner">
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>
                        </div>
                    </div>
                    <div className="gear two">
                        <div className="gear-inner">
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>
                        </div>
                    </div>
                    <div className="gear three">
                        <div className="gear-inner">
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>
                        </div>
                    </div>
                    <div className="gear four large">
                        <div className="gear-inner">
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>
                        </div>
                    </div>

                </div>
               

            </div>
        )
    }
}

export default SpinnerComponent
